.badge-lens-details{
    white-space: normal!important;
    text-align: left !important;
    font-weight: normal!important;
}
.badge-lens-details>*{
    font-size: 15px;
}
.text{
    color:#4a5568 !important;
}
.w-70px{
    width: 70px!important;
}
.text-13px{
    font-size: 13px;
}
.text-15px{
    font-size: 15px;
}
.header-lens-list,
.lens-list {
    list-style-type: none;  
}
.lens-list {
    font-size: 14px;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0;
    border: 1px solid #dee2e6;
}
.lens-list li{
    padding: 2px;
}
.lens-list .lens-label {
    cursor: pointer
}
.no-lens-warning {
    color: red;
    font-weight: bold;
}
.lens-list >li:hover,.lens-selected {
    background-color: #e7f0fe;
    color: #0d6efd;
}
/* scrollbar */
.lens-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.lens-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* icon */
.xicon-lens-details {
    height: 30px;
    margin-right: 0px;
}
.header-title-icon.xicon-lens-details {
    top:8px;
    right: 10px;
    height: 23px;
}
/* placeholder */
.light-placeholder::placeholder {
    color: #BFBFBF!important;
    opacity: 1; /* Firefox */
}
.light-placeholder:-ms-input-placeholder,/* Internet Explorer 10-11 */
.light-placeholder::-ms-input-placeholder  /* Microsoft Edge */ { 
    color: #BFBFBF!important;
}