body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button,
ul,
li,
strong,
input,
label,
th,
td,
.accordion,
.accordion ul > li > *,
#dropdown-username {
  color: #4a5568;
}

/* Titres de cards : Medium 20px  */
h2 {
  font-size: 20px;
  font-weight: medium;
}
/*Card contain : subtitle Medium 16px */
/* Boutons / input / lien cliquables : medium 16px */
h3,
button,
input,
a {
  font-size: 16px;
  font-weight: medium;
}
/* Texte courant / label input / placeholder input : Regular 16px */
p,
span,
label,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
  font-size: 16px;
}

/* Petits input (exemple impression / facture) ou Label de toggle / placeholder de textarea Regular 14px */

/* Sous catégories du menu + petit texte (exemple Bénéficiaire / Ordonnance) : 13px Medium/Regular selon les cas  */
/* Tout petit texte (description produit / ordonnance / Commentaire) : 12px Regular/Bold pour les titres */

/* Couleurs : 
Text : #4A5568 
Fond de la page : #EDF2F7 
Cards : 
background : #FFFFFF
Outline : 1px solid #E2E8F0 
Border-radius : 20px
Éléments interactifs (liens / button) : #007BFF (primary de base bootstrap)
Notifications : 
Vert #E5F4E9 
Rose #FBE7E9
Jaune #FFF3CD
Alerte inactive Gris #EFEFEF 
Contour checkbox / toggle / éléments de base : gris #B7B7B7 
Détail de commande : 
bleu de fond + séparateur #D1ECF1
Contour équipement : bleu info de bootstrap de base #17A2B8
Overlay des modal : #6C757D à 60% d’opacité 
Le reste des couleurs de base bootstrap peut être utilisé si besoin.

*/
