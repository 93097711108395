.wrapper {
  position: relative;
  overflow: hidden;
  background-color: #3f3f3f;
  padding: 5px;
  display: flex;
  align-items: center;
}

.message {
  color: lightgray;
  text-align: center;
  width: 100%;
}

/* .divcontainer:hover {
  cursor:pointer;
} */

.container {
  max-width:100%;
  max-height:100%;
  text-align: center
}

.pdfcontainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor:pointer;
}

.uploader {
  margin-top: 6px;
  width: 100%;
}

/* style of upload file input */
.file,
.filebtn {
    border: 1px solid lightgray;
    padding: 5px 10px;
}

.filebtn {
    background-color: #e5e5e5;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    cursor: pointer;
}

.file {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
    width: 220px;
    font-size: 13px;
}

.inputfile {
    width: 0;
    height: 0;
    visibility: hidden;
}

.linktofile {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  opacity: 0;
}

.linktofile:hover {
  opacity: 1.0;
}

.deletefile {
  position: absolute;
  top: 0;
  right: 0;
  width: 12%;
  height: 9%;
  padding-top: 4px;
  padding-left: 5px;
  text-align: center;
  cursor: pointer;
  opacity: 0.8;
  background-color: #3f3f3f;
  border-radius: 5px;
}

.deletefile:hover {
  opacity: 1.0;
}