.dropdown {
    position: absolute;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
	padding: 0px;
	overflow-y:auto;
	max-height: 200px;
	width: 100%;
	z-index: 1;
	li {
		padding-left: 10px;
		cursor: pointer;
		&:hover {
			background-color: #f1f1f1;
		}
	
	}
}

.inputContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}