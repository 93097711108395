.table-order th {
    padding-right: 40px;
}
.created {
    background: #dddddd;
}
.awaiting_payment {
    background: #b0c4de;
}
.in_verification,
.aftersales_check {
    background: #add8e6;
}
.awaiting_preparation {
    background: #87ceeb;
}
.in_preparation {
    background: #6495ed;
}
.delivery_in_progress,
.trial_in_progress {
    background: #00bfff;
}
.completed {
    background: #0cd100;
}
.cancelled {
    background: #ff7d7a;
}