.rstm-toggle-icon {
  display: inline-block;
}
.rstm-toggle-icon-symbol {
  width: 2rem;
  text-align: center;
}

.rstm-tree-item-group {
  list-style-type: none;
  text-align: left;
  width: 100%;
  padding: 4px;
  border: solid 1px #222;
}

.rstm-tree-item {
  font-size: 0.8rem;
  cursor: pointer;
  color: #333;
  background: none;
  box-shadow: none;
  z-index: unset;
  position: relative;
  overflow-wrap: break-word;
}
.rstm-tree-item--active {
  color: white;
  background: #0c63e4;
}
.rstm-tree-item--focused {
  box-shadow: 0 0 5px 0 #222;
  z-index: 999;
}

.rstm-tree-item-level0 {
  padding-left: 0 !important;
  font-weight: bold;
}

.rstm-tree-item-level0.rstm-tree-item--active.rstm-tree-item--focused {
  color: #333;
  background: inherit;
  box-shadow: 0 0 1px 0 #222;
}

.rstm-tree-item-level1 {
  padding-left: 2.5rem !important;
}

.gestion-commande-event-type-list {
  position: relative;
}

.spinner {
  position: absolute;
  top: 3px;
  right: 3px;
}
