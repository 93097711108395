.do-menu-accordion {
  padding: 10px 10px 10px 20px;
  position: fixed;
  top: 80px;
  bottom: 0;
  overflow-y: scroll;
  width: 295px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.do-menu-accordion::-webkit-scrollbar {
  display: none;
}
.accordion-button {
  box-shadow: none !important;
  padding: 0.8rem !important;
  font-weight: 600;
  border-radius: 10px !important;
}

.accordion-body ul{
  width: 100% !important;
}
.accordion-body li{
  margin-bottom: 0.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-link {
  text-decoration: none;
}
.accordion-button:focus {
  background-color: #edf2f7 !important;
}
.accordion-button:hover {
  background-color: #edf2f7 !important;
  color: #007bff !important;
}
.accordion-button:hover::after {
  filter: invert(34%) sepia(68%) saturate(6071%) hue-rotate(202deg)
    brightness(109%) contrast(101%);
}
.accordion-body {
  padding-top: 5px !important;
  padding-left: 5px !important;
}
.accordion-width ul {
  margin: 0;
  width: max-content;
  border-left: solid 4px rgb(0 0 0 / 5%);
}
/* pour que l'accordéon s'affiche correctement */
.accordion-width {
  min-width: 295px;
}
