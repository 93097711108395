.modalAddress .row-1 {
  display: flex;
  justify-content: space-between;
}
.modalAddress input,
.modalAddress select,
.modalAddress textarea {
  width: 270px;
}
.modalAddress textarea {
  height: 152px;
}
.modalAddress input,
.modalAddress select {
  margin-bottom: 23px;
}
.total-charcaters {
  margin: 5px auto 10px;
  width: 100%;
  color: grey;
  font-size: 12px;
  text-align: left;
}

.modalAddress a {
  font-size: 20px;
}
.modalAddress .row-1 :first-of-type input {
  margin-right: 31.5px;
}
.pickupPoint {
  width: 100%;
  justify-content: center;
  padding: 5px;
  padding-top: 6px;
  border: 1px solid #ced4da;
  margin-bottom: 20px;
  border-radius: 5px;
}
