.search-container {
  position: relative;
  z-index: 10;
  transition: width 1s;
  transition-timing-function: ease;
}

.search-input {
  position: relative;
  border-radius: 25px !important;
  padding-right: 35px !important;
  width: 410px !important;
  background-color: white !important;
  cursor: pointer;
}

.border-icon {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border: solid 1px transparent;
  background-color: transparent;
  padding: 0;
}

.search-icon {
  color: #0d6efd;
  font-size: 30px;
}
.search-icon:hover {
  color: #085ed7;
}
.search-box {
  border-radius: 10px;
  width: 440px;
  height: 130px;
  border: 1px solid lightgray;
  position: absolute;
  background-color: white;
  top: 70px;
  transform: translate(-50%, -50%);
  transition: all 2s;
}
