.alert-bar {
  background-color: white;
  width: 280px;
  top: 77px;
  right: 1px;
  position: fixed;
  overflow-y: scroll;
  bottom: 1px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-shadow: -5px 3px 10px #00000029;
  border: 1px solid #e2e8f0;
  opacity: 1;
}

.alert-bar {
  z-index: 2;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.alert-bar ::-webkit-scrollbar {
  display: none;
}
/* nécessaire pour que l'accordéon s'affiche correctement */
.alert-width {
  min-width: 280px;
  height: 100%;
}
.danger-bg {
  background-color: #fbe7e9;
}
.alert-bg {
  background-color: #fff8e1;
}
.success-bg {
  background-color: #d1e7dd;
}

/* overlay that create a focus on alert bar */
.overlay {
  position: absolute;
  top: -55px;
  bottom: -55px;
  z-index: 1;
  left: -15px;
  right: -15px;
  width: calc(100% + 15px);
  height: calc(100% + 55px);
  background: rgba(0, 0, 0, 0.3);
}

.xxs-text {
  font-size: 12px;
}

.alert-bar .header-title {
  font-size: 16px;
}
.alert-bar .header-title-icon {
  height: 20px;
}

/* marge trop importante sur les switch */
.alert-bar .form-switch {
  padding-left: 0;
}
.alert-bar .form-switch .form-check-input {
  margin-left: 0;
}

.alert-bar-button {
  opacity: 0.8;
}

.alert-bar-button-hover-color {
  background-color: #ffc107 !important;
}

.alert-bar-button-bubble {
  background-color: red;
  position: absolute;
  z-index: 10;
  top: 1px;
  right: 60px;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  opacity: 0.8;
}

.bubble-left::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 3px solid red;
  border-top: 3px solid transparent;
  border-bottom: 5px solid red;
  left: -6px;
  bottom: 5px;
  transform: rotate(-15deg);
  opacity: 0.8;
}