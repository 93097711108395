.cursor {
  cursor: pointer;
}
.page {
  display: flex;
  min-height: calc(100vh - 70px);
  background: #edf2f7;
}
.bg-navbar-sav {
  background-color: #f0f8ff !important;
}
.page-container {
  margin: 0 auto;
  width: 100%;
}
.breadcrumb {
  background-color: transparent !important;
}
.btn-radius {
  border-radius: 30px !important;
}
.small-content {
  font-size: 0.8em;
}
/* Button to toggle AccordionMenu */
.AccordionMenuBtn {
  top: 50vh;
  left: 275px;
  z-index: 2;
  cursor: pointer;
  height: 50px;
  width: 50px;
  transition: all ease-out 0.3s;
}
.AccordionMenu_close {
  left: 0;
}
.toggleAccordionMenu {
  min-height: calc(100vh - 70px);
}
/* Css globale pour les card admin */
.custom-card {
  border-radius: 20px;
  border: 1px solid #e2e8f0;
  background-color: #fff;
}

.modal-647 {
  max-width: 647px !important;
}
.modal-style {
  border-radius: 20px !important;
  overflow: hidden;
}
.modal-content {
  padding: 15px;
}
.ModalAdresse input {
  width: 270px;
}
.ModalAdresse input {
  margin-right: 20px;
}
.header-title-icon {
  height: 23px;
  margin-right: 5px;
  white-space: nowrap !important;
}
.header-no-wrap {
  white-space: nowrap !important;
}

/* color of customer badges */
.platinum {
  color: #6c757d;
}
.gold {
  color: #ffc107;
}
.silver {
  color: #ced4da;
}
.blacklist {
  color: #374151;
}
.z-index-toast {
  z-index: 9999;
}
.w-600 {
  width: 600px !important;
}
.w-400 {
  width: 400px !important;
}
.w-350 {
  width: 350px;
}
.w-320 {
  width: 320px !important;
}
.w-230 {
  width: 230px!important;
}
.w-150 {
  width: 150px!important;
}
.w-90px {
  width : 90px!important;
}

.label_pre {
  font-size: 16px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.event_highlight {
  animation: backgroundAnimation 6s 0.5s;
}
@keyframes backgroundAnimation {
  0% {
    background-color: #feb664;
  }
  25% {
    background-color: #fed564;
  }
  50% {
    background-color: #fefc64;
  }
}

/* CSS pour les pastilles du détail de la commande */
.bg-turquoise {
  background-color: #18a2b8;
}
.bg-badge-secondary {
  background-color: gray;
}
.bg-dark-blue {
  background-color: #0B7DBD
}
.bg-brown {
  background-color: #6B3000;
}

.border-primary {
  border: 1px solid #0d6efd;
  color: #0d6efd !important;
}
.violet-bg {
  background-color: #f1e7fb;
}

.bg-green {
  background-color: #d1ecf1;
}
.bg-lightgray {
  background-color: #efefef;
}
.text-lightgray {
  color: #c6c6c6 !important;
}
.text-orange {
  color: orange !important;
}
.border-orange {
  border : 1px solid orange !important;
}
.border-orange:focus{
  box-shadow: 0 0 0 0.2rem rgba(255, 140, 0, 0.25)!important;
}
.invoice-width {
  width: 60px;
}
.beneficiary-width {
  width: 270px;
}

/* CSS pour link blue */
.custom-blue-link {
  color: #0d6efd;
  cursor: pointer;
}
.custom-blue-link:hover > .custom-blue-link,
.custom-blue-link:hover {
  color: #085ed7;
}
.primary-disabled{
  color: #609CFC
}
/* z-index de PrescriptionForm */
.zindex-2 {
  z-index: 2;
}
.w-0 {
  width: 0;
}

/* col width of order detail table */
.col-30px {
  min-width: 30px;
}
.col-35px {
  min-width: 35px;
}
.col-50px {
  min-width: 50px;
}
.col-62px {
  min-width: 62px;
}
.col-80px {
  min-width: 80px;
}
.col-115px {
  min-width: 115px;
}
.w-70 {
  width: 70%;
}

/*frame list nav active */
.frame-list .nav-item .active {
  background-color: #e7f1ff !important;
  margin: 0;
  border-radius: 0;
}

/* in frame list visible scrollbar */
.frame-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.frame-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.input-error-msg {
  width: 100%;
  height: 15px;
  white-space: nowrap;
  max-width: 200px;
}

.input-label {
  margin-bottom: 0px !important;
}

.prescription-off{
    background-color:rgb(225, 223, 223);
}

.bg-off,
.prescription-off input,
.prescription-off select,
.prescription-off textarea,
.prescription-off .FileViewer
{
  background-color: lightgray !important;
}

.bg-off *{
  color: #4A5568;
}
.color-es{
  color: #E02320;
}
.color-fr{
  color: #0C34D3;
}
.color-it{
  color: #3A9245;
}