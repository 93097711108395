.tableRow {
  padding: 0.5rem 0.15rem;
}
.comment {
  width: 35%;
}
.iconSize {
  z-index: 10;
  width: 25px;
  position: absolute;
  right: 0px;
  bottom: 1px;
}
.twoLinesComment {
  max-height: 50px;
  overflow: hidden;
  opacity: 0.3;
}
.commentWrapper {
  width: 100%;
  position: relative;
}
.informationIconSize {
  width: 22px;
  margin-top: -2px;
}
.copyTooltip {
  display: block;
  position: absolute;
  top: -35px;
  left: -4px;
  background-color: white;
  padding: 5px;
  border: 1px solid #4a5568;
  border-radius: 5px;
  font-weight: bold;
  color: #4a5568;
}