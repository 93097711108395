.dropdown {
    margin-left: 5px;
}
.dropdown-menu {
    border-radius: 20px !important;
}
.oi-account-logout {
    transform: scale(-1, 1);
}
/* a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
a > span {
    margin-right: 10px;
} */

#dropdown-username {
    font-weight: bold;
}

.Profile .dropdown-centered {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.Profile .dropdown-toggle.no-btn, .PimcoreBtn .no-btn {
    background-color: transparent !important;
    border: none;
    margin: 0;
}
.Profile .dropdown-toggle.no-btn:focus {
    box-shadow: none !important;
}
.Profile .dropdown-toggle.no-caret:after {
    display: none !important;
}

.Profile .dropdown.show {
    border: none;
}