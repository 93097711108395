.brand-model-image {
  width: 140px;
}

.equipment {
  font-size: 14px;
}

.equipment-order-details span {
  display: block;
  margin-right: 30px;
}

.equipment-image {
  display: flex;
  align-items: center;
}

.product-modal-info {
  font-size: 0.9em;
}
