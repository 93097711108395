.Prescription label {
    font-size: 14px;
}

/* ----------------------- */
/* ----- Informations ---- */
/* ----------------------- */
/* color of calendar */
::-webkit-calendar-picker-indicator {
    opacity: 0.6;
}
.Informations input[type="text"],
.Ecart input[type="text"],
input[type="date"] {
    width: 150px;
    font-size: 14px;
}

/* ----------------------- */
/* ------- Ecart   ------- */
/* ----------------------- */

.Ecart select,
.Ecart label {
    width: 180px;
    font-size: 14px;
}
/* style of upload file input */
.file,
.file-btn {
    border: 1px solid lightgray;
    padding: 5px 10px;
}
.file-btn {
    background-color: #e5e5e5;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    cursor: pointer;
}
.file {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
    width: 200px;
    font-size: 14px;
}
.inputfile {
    width: 0;
    height: 0;
}
.input-container {
    position: relative;
    display: flex;
}
.input-container div:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

/* ----------------------- */
/* ---- Correction ------- */
/* ----------------------- */
/* size of div for Droit & Gauche */
.Correction > div > :first-child {
    width: 70px;
}
/* size of correction inputs */
.Correction .column {
    max-width: 80px;
}
/* ----------------------- */
/* ---- TypeVision ------- */
/* ----------------------- */
.VisionType span {
    font-size: 12px;
}
.VisionType ul {
    padding: 5px 20px;
}
.VisionType li {
    list-style: circle;
    padding: 0;
    font-size: 12px;
}
.red-icon {
    color: red;
}
.eye-side-label {
    width: 70px;
    margin-top: 18px;
}
.number-input {
    min-width: 80px;
}

.disable {
    background-color: #e9ecef !important;
}
