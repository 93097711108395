.header {
    height: 70px;
}
.stick {
    position: sticky;
    z-index: 10;
    top: 0;
}
.logo {
    height: 50px;
    margin-right: 70px;
}

.navbar-text-wrapper {
    min-width: 200px;
}

@media (max-width: 1073px) {
    .hidden-xs, .navbar-text {
        display: none;
    }
}

.order-info {
    margin-left: 10px;
    min-width: 240px !important;
}

.order-info-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.order-info-line .title {
    margin-right: 2px;
}