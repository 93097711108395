.actions-order-line {
  width: 70%;
}
/* css utilisé dans le tableau OrderDetailTable */
.border-custom {
  border: 1px solid #d1ecf1;
}
.hover-green:hover {
  background: #dbf4f8;
}
.bg-duplication{
  background: aliceblue;
}

/* --------------------------------- */
/* --Affichage de la prescription-- */
/* ------------------------------- */
.ordonnance {
  width: 550px;
  height: 650px;
}
.xs {
  display: none;
}

.divButtonOrderLineAmount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
}

.buttonValidateOrderLineAmount {
  background-color: green;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 3px 6px;
  font-size: 8px;
  cursor: pointer;
  height: 20px;
}

.buttonCancelOrderLineAmount {
  background-color: red;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 3px 6px;
  font-size: 8px;
  cursor: pointer;
  height: 20px;
}

@media (max-width: 1440px) {
  .ordonnance {
    width: 350px;
    height: 550px;
  }
  .prescription-info-ecart {
    flex-direction: column;
  }
  .prescription-upload {
    flex-direction: column;
  }
  .xs {
    display: flex;
  }
}

/* Hide Arrows From Input Number for Chrome, Edge and other with WebKit */
input.orderline-amount-input::-webkit-inner-spin-button,
input.orderline-amount-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide Arrows From Input Number for Firefox */
input.orderline-amount-input{
  -moz-appearance: textfield;
}