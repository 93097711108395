.valid-voucher {
  color: #008000;
}

.invalid-voucher {
  color: #ff0000;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 15%;
  width: 100%;
  margin: 0 auto;
}

.form-input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.form-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 60%;
  width: 100%;
  margin: 0 auto;
}

.form-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.form-label {
  font-size: 14px;
  font-weight: bold;
}

.form-error {
  color: red;
  margin: 0;
}
