.container {
    position: relative;
}

.stickyButtonContainer {
  position: sticky;
  bottom: 10px;
}

.iconSize {
  width: 25px;
  margin-left: 5px;
  color: white;
  fill: black;
  cursor: pointer;
}

.tooltipMailInfo {
  max-width: 500px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 1);
  color: white;
}