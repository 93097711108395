.order-detail-leftside {
  position: sticky;
  top: 80px;
  margin: 0;
  padding: 0;
}
.custom-mini-card{
  background: #fff;
  text-align: left;
  border-radius: 10px;
  margin: 8px;
  width: 272px;
}
.bg-orange{
  background: #f6a76b;
}
h4 {
  font-size: 18px !important;
}
h6 {
  font-size: 14px !important;
}

.beneficiary-ordo-hover {
  padding: 5px;
  border-radius: 10px;
}
.bg-turquoise > *,
.bg-turquoise h6,
.bg-turquoise h4 {
  color: #fff !important;
}

.bg-orange> *,
.bg-orange h4 {
  color: #000 !important;
}