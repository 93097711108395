.headerTitle {
    font-size: 18px;
    justify-content: flex-start;
}
.iconHeaderTitle {
    padding-right: 5px;
}
.center {
    justify-content: center;
}
.right {
    justify-content: flex-end;
}
.small {
    font-size: 1rem;
}
