.fadeIn {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeOut {
  opacity: 0;
  pointer-events:none;
}

.modal-content {
  max-height: 95vh;
  overflow-y: auto;
}