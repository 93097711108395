.pickupPointContainer {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.pickupPointList {
  width: 40%;
  height: 100%;
  /* background-color: silver; */
  overflow-y: auto;
}

.pickupPointSelected {
  color: #006400 !important;
  background-color: #dfd;
}

.pickupPointSelected strong {
  color: #006400 !important;
}

.pickupPointList ul {
  font-size: 13px;
  padding: 0;
}

/* .pickupPointListBorder {
  border-bottom: 1px solid #ced4da;
  border-radius: 5px;
} */

.pickupPointList li {
  list-style-type: none;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  padding: 4px;
}

/* .pickupPointList li:hover {
  color: #006400 !important;
  background-color: #dfd;
} */



.pickupPointList button {
  border-radius: 5px;
}

.pickupPointListButtonWrapper {
  display: flex;
  justify-content: space-around;
  margin: 5px;
}

.pickupPointMapCorner {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2px;
}

.pickupPointMapPostalCode {
  width: 100%;
  height: 8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.pickupPointMapPostalCode input {
  height: 30px;
  width: 107px;
  font-size: 15px;
}

.pickupPointMapPostalCode button {
  height: 30px;
  padding: 0px 5px 0px 5px;
}

.pickupPointMapPostalCode span {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 14px;
  white-space: nowrap;
}

.pickupPointMap {
  width: 100%;
  height: 92%;
}

.pickupPointCardContainer {
  width: 100%;
  margin-bottom: 20px;
  color: #4a5568;
}

.pickupPointCardDescription {
  display: flex;
  flex-direction: column;
}

.pickupPointCardTitle {
  font-weight: bold;
}

.pickupPointCardButton {
  display: flex;
}

.pickupPointCardAddress {
  margin-left: 3px;
}

.pickupPointCardSchedule {
  margin-top: 20px !important;
  margin-bottom: 10px;
}

.pickupPoint {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  margin-bottom: 20px;
  border-radius: 5px;
}

.pickupPointTopSpinner {
  width: 100%;
  display: flex;
  
}